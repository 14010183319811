import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { ReactSVG } from "react-svg"

// ---------------------------------------------------------

import {
  fullwidth_section,
  fullwidth_open,
  fullwidth_closed,
  close_icon_container,
  close_icon,
  video_help_text
} from "./styles.module.scss"

// ---------------------------------------------------------

const FullWidthVideoSection = ({ video, collapsible, loop, autoplay, collapsedText }) => {
  const [isSectionOpen, setIsSectionOpen] = useState(true)

  const handleClick = () => {
    const vid = document.getElementById("video")
    isSectionOpen ? vid.pause() : null
    setIsSectionOpen(!isSectionOpen)
  }

  return (
    <section
      className={classNames(fullwidth_section, isSectionOpen ? fullwidth_open : fullwidth_closed)}
    >
      {/* eslint-disable jsx-a11y/media-has-caption */}
      <video
        muted={autoplay}
        autoPlay={autoplay}
        loop={loop}
        controls
        controlsList="nodownload"
        id="video"
      >
        <source src={video.url} type="video/mp4" />
        <p>Video description: {video.description}</p>
      </video>
      {!isSectionOpen ? (
        <button
          onClick={() => {
            handleClick()
          }}
          onKeyDown={() => {
            handleClick()
          }}
        >
          <h6 className={video_help_text}>{collapsedText}</h6>
        </button>
      ) : null}
      {collapsible ? (
        <div
          role="button"
          tabIndex={0}
          className={close_icon_container}
          onClick={() => {
            handleClick()
          }}
          onKeyDown={() => {
            handleClick()
          }}
        >
          <ReactSVG className={close_icon} src={`/icons/x-mark.svg`} alt="Close modal" />
        </div>
      ) : null}
    </section>
  )
}

FullWidthVideoSection.propTypes = {
  /**
   * Specifies the video's Url
   */
  video: PropTypes.PropTypes.shape({
    url: PropTypes.string,
    description: PropTypes.string
  }),

  /**
   * Specifies if section can be collapsed
   */
  collapsible: PropTypes.bool,

  /**
   * Specifies text that is shown when module is collapsed
   */
  collapsedText: PropTypes.string,

  /**
   * Specifies if video autoplays
   */
  autoplay: PropTypes.bool,

  /**
   * Specifies if video loops
   */
  loop: PropTypes.bool
}

FullWidthVideoSection.defaultProps = {
  autoplay: true
}

export default FullWidthVideoSection
