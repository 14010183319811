import React from "react"
import PropTypes from "prop-types"
import Link from "next/link"

// ---------------------------------------------------------

import Image from "@components/image"
import ErrorBoundary from "@components/error-boundary"

// ---------------------------------------------------------

import { card_container, card_content, arrow_dark, button_container } from "./styles.module.scss"

const PeopleCard = ({ name, description, image, slug }) => {
  return (
    <ErrorBoundary>
      <Link
        href={`/people/${slug}`}
        data-testid="person-card"
        role="button"
        tabIndex={0}
        className={card_container}
      >
        <>
          {image && (
            <Image
              {...image}
              src={image.url + "?fit=crop,center&crop=faces&ar=11:14&faceindex=1"}
              alt={image.description}
              sizes="280px"
            />
          )}
          <div className={card_content}>
            <h4>{name}</h4>
            <p>{description}</p>
          </div>
          <div className={button_container}>
            <div className={arrow_dark}>Read bio</div>
          </div>
        </>
      </Link>
    </ErrorBoundary>
  )
}

PeopleCard.propTypes = {
  /**
   * Person's full name.
   */
  name: PropTypes.string.isRequired,

  /**
   * Person title.
   */
  description: PropTypes.string.isRequired,

  /**
   * Main card image.
   */
  image: PropTypes.shape({
    url: PropTypes.string,
    description: PropTypes.string
  }),

  /**
   * A short description of the person.
   */
  bio: PropTypes.string.isRequired,

  /**
   * Link to the person's page.
   */
  slug: PropTypes.string.isRequired
}

export default PeopleCard
