import React from "react"
import PropTypes from "prop-types"

import Image from "@components/image"

import { desktop_image, mobile_image } from "./styles.module.scss"

const Timeline = ({ desktopImage, mobileImage }) => {
  return (
    <>
      <div className={desktop_image}>
        <Image {...desktopImage.image} />
      </div>
      <div className={mobile_image}>
        <Image {...mobileImage.image} />
      </div>
    </>
  )
}

export default Timeline

Timeline.propTypes = {
  /**
   * The image to display on desktop.
   */
  desktopImage: PropTypes.object,
  /**
   * The image to display on mobile.
   */
  mobileImage: PropTypes.object
}
