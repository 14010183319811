import PropTypes from "prop-types"
import dynamic from "next/dynamic"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import classNames from "classnames"

// ---------------------------------------------------------

import Card from "@components/card"
import Carousel from "@components/carousel"
import Image from "@components/image"

const Button = dynamic(() => import("@components/button"), { ssr: false })

// ---------------------------------------------------------

import { transformButton } from "./transformer"

// ---------------------------------------------------------

import {
  button_primary,
  carousel,
  carousel_image,
  container,
  hero,
  hero_col_left,
  hero_text,
  label_color_limoncello,
  label_color_prime_red,
  label_color_prime_green,
  label_color_default,
  label_color_white
} from "./styles.module.scss"

const labelColorOptions = {
  limoncello: label_color_limoncello,
  "prime-red": label_color_prime_red,
  "prime-green": label_color_prime_green,
  white: label_color_white,
  default: label_color_default
}

// ---------------------------------------------------------

const ContentWithCarousel = (props) => {
  let {
    backgroundColor,
    carouselHeader,
    carouselCardsCollection,
    children,
    copy,
    image,
    label,
    labelColor,
    primaryButton,
    theme,
    title
  } = props

  // -------------------------------------------------------

  const classes = classNames(container, {
    [`background_${backgroundColor}`]: backgroundColor
  })

  // -------------------------------------------------------

  const renderedHeroCopy = documentToHtmlString(copy?.json)

  // -------------------------------------------------------

  const lightLabelColor = labelColor === "limoncello"
  const lightBackgroundColor =
    backgroundColor === "ice" ||
    backgroundColor === "white" ||
    backgroundColor === "ice-gradient" ||
    backgroundColor === "meringue-gradient"

  const determineLabelColor = (color) => {
    if (lightBackgroundColor && lightLabelColor) {
      return labelColorOptions["default"]
    } else {
      return labelColorOptions[color]
    }
  }

  return (
    <section className={classes} data-cy="content-carousel">
      <div className={hero}>
        <div className={hero_col_left}>
          {children}
          {copy && (
            <>
              {label && <h6 className={determineLabelColor(labelColor)}>{label}</h6>}
              <div
                className={hero_text}
                dangerouslySetInnerHTML={{ __html: renderedHeroCopy }}
              ></div>
            </>
          )}

          {primaryButton && (
            <Button className={button_primary} {...transformButton(primaryButton)} />
          )}

          {image && (
            <Image
              className={carousel_image}
              src={image.src}
              alt={image.alt}
              width={200}
              height={200}
            />
          )}
        </div>
        <div className={carousel}>
          {carouselHeader && <h6>{carouselHeader}</h6>}
          <Carousel uniqueId={title} contentType="card" contentCarousel theme={theme}>
            {carouselCardsCollection &&
              carouselCardsCollection.items.map((card, idx) => {
                return (
                  <Card
                    {...card}
                    hover="null"
                    label={card.label}
                    theme={card.theme === "Color split" ? card.theme : "carousel"}
                    key={idx}
                    description={card.theme === "Color split" ? card.description : null}
                    eventCategory={"Modal card click"}
                  />
                )
              })}
          </Carousel>
        </div>
      </div>
    </section>
  )
}

ContentWithCarousel.propTypes = {
  /**
   * Specifies the background color.
   */
  backgroundColor: PropTypes.oneOf([
    "anvil",
    "cement",
    "ice",
    "lagoon",
    "limoncello",
    "off-black",
    "white",
    "meringue-gradient",
    "midnight-gradient",
    "dusk-gradient",
    "deepest-pink-gradient",
    "ice-gradient",
    "ice-gradient-reversed"
  ]),

  /**
   * Cards that should be displayed in the slider.
   */
  carouselCardsCollection: PropTypes.object,

  /**
   * Header displayed above carousel.
   */
  carouselHeader: PropTypes.string,

  /**
   * HTML string that serves as the header and subheader in hero.
   */
  copy: PropTypes.object,

  /*
   * Image displayed directly below the content.
   */
  image: PropTypes.shape({
    alt: PropTypes.string,
    height: PropTypes.number,
    src: PropTypes.string.isRequired,
    width: PropTypes.number
  }),

  /**
   * Label text. displayed above the copy.
   */
  label: PropTypes.string,

  /**
   * Label color.
   */
  labelColor: PropTypes.oneOf(["limoncello", "prime-green", "prime-red", "off-black", "white"]),

  /**
   * Main call to action in hero.
   */
  primaryButton: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
    labelAttr: PropTypes.string,
    theme: PropTypes.string
  }),

  /**
   * Specifies a theme for Carousel.
   */
  theme: PropTypes.oneOf(["light", "dark"]),

  /**
   * Title of module used for organization.
   */
  title: PropTypes.string
}

ContentWithCarousel.defaultProps = {
  backgroundColor: "white",
  labelColor: "off-black",
  theme: "dark"
}

export default ContentWithCarousel
